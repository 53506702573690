<template>
  <RealisasiKpiDivisiForm mode="Detail" module="Realisasi KPI"> </RealisasiKpiDivisiForm>
</template>

<script>
import RealisasiKpiDivisiForm from './form-realisasi';

const RealisasiKpiDivisiUpdate = {
  name: 'RealisasiKpiDivisiUpdate',
  components: { RealisasiKpiDivisiForm },
};

export default RealisasiKpiDivisiUpdate;
</script>
